import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export const AuthGuard = async () => {
  const router: Router = inject(Router);
  const authService = inject(AuthService);
  const platformId = inject(PLATFORM_ID);
  // const isTokenValid = await authService.checkToken();

  if (isPlatformServer(platformId)) {
    //
  } else if (isPlatformBrowser(platformId)) {
    if (authService.getLocalstorage('refreshToken')) {
      if (authService.isTokenValid('refreshToken')) {
        if (authService.isTokenValid('accessToken')) {
          if (authService.isRegistrationCompleted()) {
            return true;
          } else {
            authService.getRegistrationDetail();
            return false;
          }
        } else {
          const result = await authService.generateAccessToken();
          if (!result) {
            authService.clearLocalStorage();
            router.navigateByUrl(`auth/login`);
            return false;
          } else {
            if (authService.isRegistrationCompleted()) {
              return true;
            } else {
              authService.getRegistrationDetail();
              return false;
            }
          }
        }
      } else {

        await authService.logoutUser();
        return false;
      }
    } else {
      router.navigateByUrl(`auth/login`);
      return false;
    }
  }
  return false;
};
